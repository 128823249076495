//https://sgm-status-azure-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen/api/v1/healths/https%3A%2F%2Fapi-z.sgmarkets.com%2Fservices%2Finsight%2Fprivate%2F?getTraffic=true
import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type AzureHealthDto } from '@/models/azureHealth.ts'
import { getConfig } from '@/config/config.ts';


export class AzureHealthService {
  get(url: string): Promise<AzureHealthDto> {

    const { azureApiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;
    return fetch(url, requestOptions)
    .then((_response: Response) => {
        if( _response.ok)
          return _response.json();
        else {
          if (_response.status == 404)
            throw new Error('Url not found');
          else 
            throw new Error('An error occurs.');
        }
    });
  }
}