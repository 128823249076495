import { Fragment, useEffect, useState } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { BUS_TOPIC_GLOBALLANGUAGE, getWidgetBus } from '@/sgwt/widgets.ts';
import en from '@/locale/en.json';
import fr from '@/locale/fr.json';

type SupportedLocale = 'en' | 'fr';
export const locales: Record<SupportedLocale, Record<string, string>> = {
  en,
  fr: { ...en, ...fr },
};

export const IntlProvider = ({ children }: { children: JSX.Element }) => {
  const [locale, setLocale] = useState<SupportedLocale>('en');

  // Use the SGWT Widgets bus to be aware of the modification of the language,
  // which is triggered by the <sgwt-account-center> widget.
  useEffect(() => {
    const bus = getWidgetBus();
    if (bus) {
      const languageSubscription = bus.subscribe<string>(BUS_TOPIC_GLOBALLANGUAGE, lang => {
        if (lang && Object.keys(locales).includes(lang.toLowerCase())) {
          setLocale(lang.toLowerCase() as SupportedLocale);
          document.querySelector('html')!.lang = lang;
        }
      });
      return () => {
        bus.unsubscribe(languageSubscription);
      };
    }
  }, []);

  return (
    <ReactIntlProvider
      locale={locale}
      defaultLocale="en"
      messages={locales[locale]}
      textComponent={Fragment}
    >
      {children}
    </ReactIntlProvider>
  );
};
