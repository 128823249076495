import './App.css';
import { Footer } from '@/components/layout/Footer.tsx';
import { Header } from '@/components/layout/Header.tsx';
import { IntlProvider } from '@/context/IntlContext.tsx';
import { SgwtWebAnalytics } from '@sgwt/sgwt-widgets-react';
import { getConfig } from '@/config/config.ts';
import { RouterProvider } from 'react-router';

import { router } from '../app/router.tsx';

export function App() {
  const { matomo } = getConfig();

  return (
    <IntlProvider>
        <RouterProvider router={router} />
    </IntlProvider>
  );
}
