import { type ReactElement, useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { LayoutService } from '@/services/LayoutService.ts'
import { type ApisDto, type ApiDto } from '@/models/graph.ts'
import { type DataGraph, type Link, type Instance, type DrawGraphNode, type virtualLink } from '@/models/graph.ts'
import DxChart from '../../components/dxChart/dxChart.tsx'
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import type { LayoutDto } from '@/models/layout.ts';


export function DxApiChartPage(): ReactElement {

  const graphNodes: DrawGraphNode[] = [];
  const [dataGraph, setDataGraph] = useState<DrawGraphNode[]>(graphNodes);
  const [id, setId] = useState<number>(100);
  const [apiName, setApiName] = useState<string>("");
  const [layout, setLayout] = useState<{ [id: string] : any; }>({});
  
  let { key } = useParams();
  let { environment } = useParams();
  const applicationsService = new ApplicationsService();
  const layoutService = new LayoutService();

  function ParseChild(parentId: string, array: DataGraph[], child: ApiDto, savedPositions:{ [id: string] : any; }) {
    const item = {} as DataGraph;

    item.id = child.key;
    setId(item.id as any);
    item.parentId = parentId;
    item.name = child.name == null ? child.key : child.name;
    item.status = child.status;
    item.statusFailureReason = child.statusFailureReason;
    item.messageInfo = child.messageInfo;
    item.warningMessage = "";
    item.type = child.type;
    item.applicationLinks = [];
    item.nodes = [];
    child.instances?.map((instance: Instance) => {
      item.nodes!.push(instance.status);
    });
    array.push(item);


    if (graphNodes.filter( (f) => f.data.id == item.id ).length == 0) {
      if (savedPositions[child.key] != undefined) {
        graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: 'black', type: 'api', level: 150}, position: savedPositions[child.key] });
      } 
      else {
        graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: 'black', type: 'api', level: 150} });
      }
    }
    graphNodes.push({ data: { source: parentId, target: item.id, arrow: "triangle"  } });

    child.children?.map((_child: ApiDto) => {
      ParseChild(item.id, array, _child, savedPositions);
    });
  }

  function ParseParent(parentId: string, array: DataGraph[], child: ApiDto, level: number, savedPositions:{ [id: string] : any; }) {
    const item = {} as DataGraph;

    item.id = child.key;
    setId(item.id as any);
    item.parentId = parentId;
    item.name = child.name == null ? child.key : child.name;
    item.status = child.status;
    item.statusFailureReason = child.statusFailureReason;
    item.messageInfo = child.messageInfo;
    item.warningMessage = "";
    item.type = child.type;
    item.applicationLinks = [];
    item.nodes = [];
    array.push(item);
 
    if (graphNodes.filter( (f) => f.data.id == item.id ).length == 0) {
      if (savedPositions[child.key] != undefined) {
        graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: 'blue', type: 'api', level: level}, position: savedPositions[child.key]  });
      } 
      else {
        graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: 'blue', type: 'api', level: level} });
      }
    }

    if (item.id != parentId)
        graphNodes.push({ data: { source: item.id, target: parentId, arrow: "triangle"  } });
    child.parents?.map((_parent: ApiDto) => {
        ParseParent(child.key, array, _parent, level-5, savedPositions);
    });
  }


function ChooseColorAccoringToCountLinks(countLink: number): any {
  const colors = [
    '#212afd',
    '#9800d8',
    '#be00a7',
    '#d40086',
    '#e70069',
    '#f70047',
    '#ff0000',
  ]
  if (countLink > 6)
    return colors[6]
  else if (countLink == 0)
   return colors[0]
  return colors[countLink-1];
}

function AjustNodeColor(graphNodes: DrawGraphNode[]) {
  graphNodes.map((_child: DrawGraphNode) => {
    let countLink = 0;
    if (_child.data.color != "green" && _child.data.color != "black") {
      graphNodes.map((_childLoop: DrawGraphNode) => {
        if (_childLoop.data.target != null && _child.data.id != null && _childLoop.data.target == _child.data.id && !_child.data.links?.includes(`|${_childLoop.data.target}|`))
        {
            if (_child.data.links == undefined) {
                _child.data.links = '';
            }
            countLink++;
            _child.data.links += `|${_childLoop.data.target}`;
            _child.data.countLink = countLink;
            _child.data.color = ChooseColorAccoringToCountLinks(countLink);
        }
      });
    }
  });
}

  function RefreshData(savedPositions:{ [id: string] : any; }) {
    applicationsService.getApiGraph(key, environment).then((applications: ApisDto) => {
      const array: DataGraph[] = [];

      var data = applications.api;
      setApiName(data.name);
      const item = {} as DataGraph;
      item.id = data.key;
      item.name = data.name == null ? data.key : data.name;
      item.status = data.status;
      item.statusFailureReason = data.statusFailureReason;
      item.warningMessage = data.calculatedFrom == "Browser" ? "Item computed from browser testing, not based on subscription" : "";
      item.messageInfo = data.messageInfo;
      item.type = data.type;
      array.push(item);
      if (savedPositions[data.key] != undefined) {
        graphNodes.push({ data: { id: item.id, key: data.key, label: item.name, color: 'green', type: 'api', level: 100}, position: savedPositions[data.key] });
      }
      else {
        graphNodes.push({ data: { id: item.id, key: data.key, label: item.name, color: 'green', type: 'api', level: 100} });
      }
      data.parents?.map((parent) => {
        ParseParent(data.key, array, parent, 90, savedPositions);
      });
      data.children?.map((child) => {
        ParseChild(data.key, array, child, savedPositions);
      });
      AjustNodeColor(graphNodes);
      setDataGraph(graphNodes);

    });
};

  useEffect(() => {
    LoadLayout();
  }, []);

  const LoadLayout = () => {
    layoutService.getLayout(key).then((layout: LayoutDto) => {
      var elts: { [id: string] : any; } = {};
      if (layout.layout != null) {
        JSON.parse( layout.layout+"" ).elements.nodes.map((node: any) => {
          elts[node.data.key] = node.position;
        });
      }
      setLayout(elts); 
      RefreshData(elts);
    });
  };
  
  
  const SaveLayout = (layout: string) =>  {
    layoutService.saveLayout(key, layout);
  };
  
  const title = `Api graph for "${apiName}" (based on subscription)`;
 return dataGraph.length > 0 ? 
    <>
        <Breadcrumb pageName={title} parentName='Services' parentLink='/services' />
        <DxChart nodes={dataGraph } environment={environment} cytoscapeLayout={layout[key +""]== undefined ? "dagre" : "preset"} saveLayout={SaveLayout}  />
    </>
    :
     <></>;
}
