import { Link } from 'react-router-dom';

const UnauthorizedAccess: React.FC = () => {
    return (
        <section className="container mt-5">
            <div className="row d-flex justify-content-center py-5">
                <div className="col-md-6 text-center">
                    <div className="d-flex align-items-center flex-column w-100">
                        <div className="rounded-circle bg-primary-alpha-lg d-flex align-items-center justify-content-center mb-4" style={{ width: " 5rem", height: " 5rem", }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="48" viewBox="0 0 24 24">
                                <path fill="none" d="M0 0h24v24H0V0z"></path>
                                <path fill="#30333" xmlns="http://www.w3.org/2000/svg" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                            </svg>
                        </div>
                        <h3 className="text-center" id="i18n-title">You are not authorized to access this service</h3>
                    </div>
                    <p className="mt-3 mb-5">
                        <span id="app-name" className="font-weight-bold">This specific drill down view of  SG|Markets Status </span>
                        <span id="i18n-accessRights">requires specific access rights.
                            <span id="sgm-access-button-info" className="">
                                <span id="i18n-sgmAccessLeft">You need to request profils 'Advanced User' for resources 'SGM Status' in SG|IAM</span>
                                <span id="i18n-sgmAccessRightsRight"> to register your interest for this service.</span>
                            </span>
                        </span></p>
                    <div id="content" className="d-none py-3"></div>
                    <div>
                        <Link to="/"  className="btn btn-lg btn-flat-secondary mt-2">Go Back</Link>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default UnauthorizedAccess;