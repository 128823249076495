import { type ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { HealthGraphService } from '@/services/HealthGraphService.ts'

import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type HealthGraphDto, type ServiceGraphDto } from '@/models/healthGraph.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import './healthChart.css';
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Nav, NavLink, NavItem  } from 'reactstrap';
import HealthTree from './healthTree.tsx'
import UnauthorizedAccess from './UnauthorizedAccess.tsx';
import ErrorPage from './ErrorPage.tsx';

export function HealthChart(): ReactElement {

    let { key } = useParams();
    let { env } = useParams();
    
    const [application, setApplication] = useState<ApplicationDto>();
    const [currentEnvironment, setCurrentEnvironment] = useState<string>();
    const [healthGraphDto, setHealthGraphDto] = useState<ServiceGraphDto>();
    const [Authorized, setAuthorized] = useState<boolean>(false);
    const [Error, setError] = useState<boolean>(false);
    
    const [LoadingSt, setLoading] = useState<boolean>(true);
    const [showSearchText, setShowSearchText] = useState<string>('');
    const [ChartLayout, setChartLayout] = useState<string>('top');
    const [showConnection, setShowConnection] = useState<boolean>(false);
    

    const refreshServiceData = async (): Promise<void> => {
        try {
            // Fetch applications data from the service
            const applications: ApplicationsDto | null = await new ApplicationsService().get();
            
            // Exit early if no applications data is received
            if (!applications) return;

            // Find the specific application based on the key
            const app = applications.webApps?.find((f) => f.key === key);
            
            // Exit early if no matching application is found
            if (!app) return;

            // Update the state with the fetched application data
            setApplication(app);

            // Determine the current environment, using 'env' or defaulting to the first available environment
            let currentEnv = env ?? app.environments[0].name;
            setCurrentEnvironment(currentEnv);
            
            // Fetch health graph data for the current service and environment
            const healthGraph: HealthGraphDto | null = await new HealthGraphService().get(key, currentEnv);

            // Update the state with the fetched health graph data if it's available
            if (healthGraph) {
                setAuthorized(true);
                setError(false);
                try {
                    setHealthGraphDto(healthGraph.service);
                }
                catch (reason: any) {
                    // Set authorization to false in case of an error
                    setError(true);
                }
            } else {
                setAuthorized(false);
            }
        } catch (reason: any) {
            // Set authorization to false in case of an error
            setAuthorized(false);
        } finally {
            // Ensure the loading state is set to false, regardless of success or failure
            setLoading(false);
        }
    }
    
    useEffect(() => {
        refreshServiceData();
    }, []);
    
  
    const changeViewConnection = (): void => {
        setShowConnection(!showConnection);
    }

    const ChangeLayout = () => {
        const newLayout = ChartLayout === 'top' ? 'left' : 'top';
        window.chart.layout(newLayout).render().fit();
        setChartLayout(newLayout);
    }

    const filterChart = (e: any): void => {
        const value = e.currentTarget.value.toLowerCase();
        const _chart = window.chart;
        setShowSearchText(value);
      
        // Clear existing highlighting
        _chart.clearHighlighting();
      
        // Update data with new highlighting
        const updatedData = _chart.data().map(d => ({
          ...d,
          _highlighted: value ? d.name.toLowerCase().includes(value) : false
        }));
      
        // Render the chart with updated data
        _chart.data(updatedData).render().fit();
      }

    return (

        <div>
            {LoadingSt === true ? <Loading isLoading= {LoadingSt} />
            :
            Authorized == true ? 
            <>
                <Breadcrumb pageName={application?.name!} parentName='Home' parentLink='/' />
                <Nav tabs>
                    {application?.environments.map((environment) =>(
                        <NavItem key={`nav_${environment.name}`} >
                            <NavLink as={Link} href={`/healths/${key}/${environment.name}`} key={environment.name} className={currentEnvironment == environment.name ? "active" : '' }>{environment.name}</NavLink>
                        </NavItem>
                    ))
                    }
                </Nav>
                <div className='healthTreeContainer'>
                    <div className='layoutOptions'>
                    <div className='mb-3'><Link  to={`/table/${key}`} ><img src='/chart/table_view.png' style={{width: '48px'}} title='Switch to table view'></img></Link></div>
                    <div className='mb-3'><img src='/chart/graph.png' style={{width: '48px'}}  onClick={ changeViewConnection } title='Show all connections'></img></div>
                    <div className='mb-3'><img src='/chart/layout-vertical.svg' style={{width: '48px'}}   onClick={ ChangeLayout } title='Switch layout to Horizontal/Vertical'></img></div>
                    <div className='mb-3 search-box'><input type='text' className={`search-input`}  onChange={filterChart} value={showSearchText} placeholder="Search for an Api"  ></input><a className="search-btn" href="#"><img src='/chart/search.png' className='search-btn' style={{width: '42px', paddingLeft: '7px'}} ></img></a></div>
                </div>
                    <HealthTree service={healthGraphDto!} showConnection={showConnection} refreshServiceData={refreshServiceData} />
                </div>
            </> : ( Error == false ? <UnauthorizedAccess /> : <ErrorPage />
                )
            }
        </div>
    );
}