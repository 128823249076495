import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type HealthTableDto } from '../models/healthGraph.ts'
import { getConfig } from '@/config/config.ts';


export class HealthTableService {
  get(key?: string): Promise<HealthTableDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/webapps/${key}/tables`, requestOptions)
    .then((_response: Response) => {
        if( _response.ok)
          return _response.json();
        else
        throw new Error('No Authorization');
    });
  }
}