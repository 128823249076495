import { type ReactElement } from 'react';
import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';
import { HealthChart} from '../components/healths/healthChart.tsx'
import { Home} from '../components/home/home.tsx'
import { HealthTable } from '@/components/healths/healthTable.tsx';

export function HealthsTablePage(): ReactElement {

  if (sgwtConnect.isAuthorized())
    return <HealthTable/>;
  return <HealthTable />
}