import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type ApplicationDto } from '../models/services.ts'
import { type APIConfigurationDto, type APIEntryDto, type CheckExistanceDto } from '../models/apiConfiguration.ts'
import { getConfig } from '@/config/config.ts';


export class ApiConfigurationService {
  
  saveSettings(application: ApplicationDto | null, producerCode: string | undefined, apiEntries : APIEntryDto[]): Promise<boolean> {
    return this.savePartialIncidents(application, producerCode).then((t) => {
      return this.saveApiEnvironments(application, apiEntries).then((s) => {
        return true;
      })
    });
  }

  
  saveApiEnvironments(application: ApplicationDto | null, apiEntries : APIEntryDto[]): Promise<boolean> {
    const { apiUrl } = getConfig();
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ apiEntries: apiEntries }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    return fetch(
      `${apiUrl}/api/v2/webapps/${application?.key}/configure-apis`,
      requestOptions as any
    ).then((t) => {
      return true;
    });  
  }


  savePartialIncidents(application: ApplicationDto | null, producerCode: string | undefined): Promise<boolean> {
    const { apiUrl } = getConfig();
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ webAppKey: application?.key, producerCode: producerCode }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    return fetch(
      `${apiUrl}/api/v2/partial-incidents`,
      requestOptions as any
    ).then((t) => {
      return true;
    });  
  }

  getConfigureApis(key: string, apiEntries: string[]): Promise<APIConfigurationDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({ apiEntries: apiEntries }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/webapps/${key}/configure-apis/do-search`, requestOptions)
    .then((_response: Response) => {
        return _response.json();
    });
  }

  checkApiExistance(key: string): Promise<CheckExistanceDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/apis/${key}/check-existance/`, requestOptions)
    .then((_response: Response) => {
        return _response.json();
    });
  }
}