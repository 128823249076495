import { type ReactElement, useEffect, useState  } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { LayoutService } from '@/services/LayoutService.ts'
import { type ServicesDto, type ServiceDto, type VirtualLinkDto } from '@/models/graph.ts'
import { type DataGraph, type Link, type Instance, type DrawGraphNode, type virtualLink } from '@/models/graph.ts'
import DxChart from '../../components/dxChart/dxChart.tsx'
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import type { LayoutDto } from '@/models/layout.ts';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText  } from 'reactstrap';

export function DxChartPage(): ReactElement {

  const graphNodes: DrawGraphNode[] = [];
  const [dataGraph, setDataGraph] = useState<DrawGraphNode[]>(graphNodes);
  const [id, setId] = useState<number>(100);
  const [apiName, setApiName] = useState<string>("");
  const [layout, setLayout] = useState<{ [id: string] : any; }>({});
  const applicationsService = new ApplicationsService();
  const layoutService = new LayoutService();

  let { key } = useParams();
  let { environment } = useParams();

  const ParseChild = (parentId: string, array: DataGraph[], child: any, level: number, savedPositions:{ [id: string] : any; }) => {
    const item = {} as DataGraph;

    item.id = child.key;
    setId(item.id as any);
    item.parentId = parentId;
    item.name = child.name == null ? child.key : child.name;
    item.status = child.status;
    item.statusFailureReason = child.statusFailureReason;
    item.messageInfo = child.messageInfo;
    item.warningMessage = "";
    item.type = child.type;
    item.nodes = [];
    child.instances?.map((instance: Instance) => {
      item.nodes!.push(instance.status);
    });
    array.push(item);
    if (savedPositions[child.key] != undefined) {
      graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: child.children.length ==  0 ? '#212afd' : '#d40086', type: 'api', level: level}, position: savedPositions[child.key] });
    }
    else {
      graphNodes.push({ data: { id: item.id, key: child.key, label: item.name, color: child.children.length ==  0 ? '#212afd' : '#d40086', type: 'api', level: level} });
    }
    if (parentId !=  item.id) {
      graphNodes.push({ data: { source: parentId, target: item.id, arrow: "triangle"  } });
    }

    child.childrenLinks?.map((_child: string) => {
      if (_child !=  item.id) {
        graphNodes.push({ data: { source: item.id, target: _child, arrow: "triangle"  } });
      }
    });
    child.virualLinks?.map((link: any) => {
      if (link.from !=  link.to) {
        console.log("draw: " + link.from+"->"+link.to);
        graphNodes.push({ data: { source: link.from, target: link.to, arrow: "triangle"  } });
      }
    });
    child.children?.map((_child: ServiceDto) => {
      ParseChild(item.id, array, _child, level-1, savedPositions);
    });
  }


  const  RefreshData = (savedPositions:{ [id: string] : any; } ) => {
    applicationsService.getGraph(key!, environment == "non-production" ? false : true).then((applications: ServicesDto) => {
      const array: DataGraph[] = [];

      var envIndex = 0;
      // applications.webApps.map((item, index) => {
      //   if (item.environment == environment)
      //     envIndex = index;
      // });
      var data = applications.webApps[envIndex];
      const item = {} as DataGraph;
      setApiName(data.name);
      item.id = data.name == null ? data.key : data.name;
      item.name = data.name == null ? data.key : data.name;
      item.status = data.status;
      item.statusFailureReason = data.statusFailureReason;
      item.warningMessage = data.calculatedFrom == "Browser" ? "Item computed from browser testing, not based on subscription" : "";
      item.messageInfo = data.messageInfo;
      item.type = data.type;
      item.applicationLinks = [];
      array.push(item);
      if (savedPositions[data.key] != undefined) {
        graphNodes.push({ data: { id: item.id, key: data.key, label: item.name, color: 'green', type: 'webapp', level: 1000}, position: savedPositions[data.key] });
      } 
      else {
        graphNodes.push({ data: { id: item.id, key: data.key, label: item.name, color: 'green', type: 'webapp', level: 1000} });
      }

      // data.virtualLinks?.map((link: VirtualLinkDto) => {
      //   if (link.from !=  link.to) {
      //     console.log("draw: " + link.from+"->"+link.to);
      //     graphNodes.push({ data: { source: link.from, target: link.to, arrow: "triangle"  } });
      //   }
      // });
      data.children?.map((child) => {
        ParseChild(item.name, array, child, 995, savedPositions);
      });
      //AjustNodeColor(graphNodes);
      setDataGraph(graphNodes);

    });
  };

  useEffect(() => {
    LoadLayout();
  }, []);


  const title = `Application graph for "${apiName}" (based on subscription)`;

  const LoadLayout = () => {
    layoutService.getLayout(`"${key}_${environment}"`).then((layout: LayoutDto) => {
      var elts: { [id: string] : any; } = {};
      if (layout.layout != null) {
        JSON.parse( layout.layout+"" ).elements.nodes.map((node: any) => {
          elts[node.data.key] = node.position;
        });
      }
      setLayout(elts); 
      RefreshData(elts);
    });
  };


  const SaveLayout = (layout: string) =>  {
    layoutService.saveLayout(`"${key}_${environment}"`, layout);
  };



return dataGraph.length > 0 ? 
   <>
      <Breadcrumb pageName={title} parentName='Services' parentLink='/services' />
      <Nav tabs>
        <NavItem>
          <NavLink href={`/dx-chart/${key}/production`} className={environment === 'production' ? "active" : '' }>Production Environment</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href={`/dx-chart/${key}/non-production`}  className={environment === 'non-production' ? "active" : '' }>Non-Production Environment</NavLink>
        </NavItem>
      </Nav>
       <DxChart nodes={dataGraph } cytoscapeLayout={layout[key +""]== undefined ? "dagre" : "preset"} key={key} saveLayout={SaveLayout} />
   </>
   :
    <></>;
}