import { type ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { HealthTableService } from '@/services/HealthTableService.ts'
import { AzureHealthService } from '@/services/AzureHealthService.ts'
import { HealthService } from '@/services/HealthService.ts';

import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import { type HealthTableDto, type ServiceTableDto } from '@/models/healthGraph.ts'
import { type AzureHealthDto } from '@/models/azureHealth.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import './healthChart.css';
import './healthTable.css';
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText  } from 'reactstrap';
import HealthTree from './healthTree.tsx'

export function HealthTable(): ReactElement {

    let { key } = useParams();
    
    const [application, setApplication] = useState<ApplicationDto>();
    const [healthTableDto, setHealthTableDto] = useState<ServiceTableDto[]>();
    const [Authorized, setAuthorized] = useState<boolean>(false);
    const [LoadingSt, setLoading] = useState<boolean>(true);
    const [showConnection, setShowConnection] = useState<boolean>(false);
    const healthService = new HealthService();
    
    const timeRefresh = 60000;

    const formatHealth = (str) => {
        if (str == null)
            str = "Undefined";
        else if (str.toLowerCase() == "up")
            str = "healthy";
        else if (str.toLowerCase() == "down")
            str = "unhealthy";
        return str.charAt(0).toUpperCase() + str.slice(1);
        }

    const refreshServiceData = (): void => {
        new ApplicationsService().get().then((applications: ApplicationsDto) => {
            const app = applications?.webApps
                ?.filter(
                  (f) =>
                    f.key == key
                )
                
            setApplication(app[0]);
            new HealthTableService().get(key).then((healthGraph: HealthTableDto) => {
                setAuthorized(true);
                healthGraph.services.map((child) => {
                    if (child.healthUri != null) {
                        healthService.get(child.healthUri!).then((result) => {
                            console.log(child.healthUri+":"+result.status);
                            child.status = formatHealth(result.status);
                        }).catch((error) => {
                            console.log(child.healthUri+":"+error);
                            child.status = "Undefined";
                        }).finally(()=> {
                            setHealthTableDto([... healthGraph.services]);
                        });
                    }
                });
                setHealthTableDto(healthGraph.services)
            })
            .catch((reason: any) => {
                setAuthorized(false);
            })
            .finally( () => {
                setLoading(false);
            })

        });
    }

    const refreshDataHealth = (): void => {
        healthTableDto?.map((child) => {
            if (child.healthUri != null) {
                healthService.get(child.healthUri!).then((result) => {
                    console.log(child.healthUri+":"+result.status);
                    child.status = formatHealth(result.status);
                }).catch((error) => {
                    console.log(child.healthUri+":"+error);
                    child.status = "Undefined";
                }).finally(()=> {
                    setHealthTableDto(healthTableDto)
                });
            }
        });
    }
    
    useEffect(() => {
        refreshServiceData();
        const interval = setInterval(() => {
            refreshDataHealth();
        },timeRefresh);
        return () => clearInterval(interval);
    }, []);
    

    const getLinkIcon = (type: string) => {
        switch(type) {
            case 'do-it-now':
                return "din.png";
            default:
                return type+".svg"
        }
    } 

    const GetType = (type: string) => {
        if (type === "web-apps") {
            return "/chart/cdn.png";
        }
        if (type === "website") {
            return "/chart/cdn.png";
        }
        if (type === "api") {
            return "/chart/api.png";
        }
        if (type === "database") {
            return "/chart/db_healthy.png";
        }
        if (type === "frontdoor") {
            return "/chart/frontdoor.svg";
        }
        if (type === "apim") {
            return "/chart/apim.svg";
        }
        if (type === "appgateway") {
            return "/chart/appgateway.svg";
        }
        return "/chart/health.svg";
    }

    const GetTextColor = (status: string) => {
        if (status == 'Degraded')
            return 'text-degraded';
        else if (status == 'Unhealthy')
            return 'text-unhealthy';
        return ''
    }
    const GetStatusTextColor = (status: string) => {
        if (status == 'Degraded')
            return 'status-text-degraded';
        if (status == 'Healthy')
            return 'status-text-healthy';
        else if (status == 'Unhealthy')
            return 'status-text-unhealthy';
        return ''
    }

    return (

        <div>
            {LoadingSt === true ? <Loading isLoading= {LoadingSt} />
            :
            Authorized == true ? 
            <>
                <Breadcrumb pageName={application?.name!} parentName='Home' parentLink='/' />
                <Link className='btn btn-round btn-info mb-3' style={{ float:'right' }}  to={`/healths/${key}`} >Graph view</Link>
                <div className='healthTreeContainer'>
                    <table className='table table-sm table-striped table-hover'>
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>Name</td>
                                <td>Type</td>
                                <td>Environment</td>
                                <td>Status</td>
                                <td>IAppli</td>
                                <td>Links</td>
                            </tr>
                        </thead>
                        <tbody>

                   {
                    healthTableDto?.filter(item => item.type =='api' || item.type.indexOf( "Azure") == 0).map((service) => (
                        <tr style={{ height: "35px" }} key={service.name+"-"+service.environment}>
                            <td className={GetTextColor(service.status)} ><img src={GetType(service.type)} style={{width: "25px", height:"25px", padding: '0'}} /></td>
                            <td className={GetTextColor(service.status)} ><a href={service.apiDeclaration?.baseEndPointUri} target='_blank'>{service.name}</a></td>
                            <td className={GetTextColor(service.status)} ><a href={service.apiDeclaration?.swaggerFileUri} target='_blank'>{service.type}</a></td>
                            <td className={GetTextColor(service.status)} >{service.environment}</td>
                            <td className={GetTextColor(service.status)} ><a href={service.healthUri} target='_blank' className={GetStatusTextColor(service.status)}>{service.status}</a></td>
                            <td className={GetTextColor(service.status)} >{service.apiDeclaration?.iAppliName}</td>
                            <td className={GetTextColor(service.status)}>{service.applicationLinks?.map((link) => (
                                <a href={link.url} target='_blank'><img src={`/chart/${getLinkIcon(link.type)}`} style={{width:'25px', height:"25px" }} className="link" title={link.name} /></a>
                            ))}</td>
                        </tr>
                    ))
                   }
                   </tbody>
                    </table>
                </div>
            </> : 
            <>
            <section className="container mt-5">
                <div className="row d-flex justify-content-center py-5">
                    <div className="col-md-6 text-center">
                    <div className="d-flex align-items-center flex-column w-100">
                        <div className="rounded-circle bg-primary-alpha-lg d-flex align-items-center justify-content-center mb-4" style={{ width: " 5rem", height: " 5rem",  }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="48" viewBox="0 0 24 24">
                            <path fill="none" d="M0 0h24v24H0V0z"></path>
                            <path fill="#30333" xmlns="http://www.w3.org/2000/svg" d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
                        </svg>
                        </div>
                        <h3 className="text-center" id="i18n-title">You are not authorized to access this service</h3>
                    </div>
                    <p className="mt-3 mb-5">
                        <span id="app-name" className="font-weight-bold">This specific drill down view of  SG|Markets Status </span>
                        <span id="i18n-accessRights">requires specific access rights.
                        <span id="sgm-access-button-info" className="">
                        <span id="i18n-sgmAccessLeft">You need to request profils 'Advanced User' for resources 'SGM Status' in SG|IAM</span>
                        <span id="i18n-sgmAccessRightsRight"> to register your interest for this service.</span>
                        </span>
                    </span></p>
                    <div id="content" className="d-none py-3"></div>
                    <div>
                        <Link to="/"  className="btn btn-lg btn-flat-secondary mt-2">Go Back</Link>
                    </div>
                    </div>
                </div>
                </section>
            </>
            }
        </div>
    );
}