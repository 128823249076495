import { type ReactElement, type FC, useEffect, useState, useCallback } from 'react';
import type CytoscapeComponentProps from 'react-cytoscapejs';
import CytoscapeComponent from 'react-cytoscapejs';
import { type DataGraph, type Link, type Instance, type DrawGraphNode } from '@/models/graph.ts'
import { Button } from 'reactstrap';
import cytoscape from 'cytoscape';
import dagre from 'cytoscape-dagre';
import cxtmenu from 'cytoscape-cxtmenu';
import cytoscapePopper from 'cytoscape-popper';
import tippy from 'tippy.js';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import './dxChart.css'

cytoscape.use( dagre );
cytoscape.use( cxtmenu );
function popperFactory(ref: any, content: any, opts: any): any {
  // See integration sections
}

cytoscape.use( cytoscapePopper(popperFactory) );

export type DxChartProps = {
    nodes: DrawGraphNode[],
    environment?: string | null,
    cytoscapeLayout: string,
    saveLayout: (layout: string) => void;
  };

  
const DxChart: FC<DxChartProps>= ({
    nodes,
    environment,
    cytoscapeLayout,
    saveLayout,
}: DxChartProps) => {

  
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

    const layout = {
        name: cytoscapeLayout,
        directed:true,
        nodeDimensionsIncludeLabels: true, 
        acyclicer: 'greedy',
        //depthSort: (a: any, b: any) => a.data('level') - b.data('level') ,
        ranker:  'network-simplex', // Type of algorithm to assign a rank to each node in the input graph. Possible values: 'network-simplex', 'tight-tree' or 'longest-path'
      };
    
      const stylesheet = [{
        "selector": "node",
        "style": {
          "text-valign": "top",
          "text-halign": "center",
          "width": 96,
          "height": 96,
          "font-size": "30px",
          "background-color": "data(color)",
          "label": "data(label)"
        }
      }, {
        "selector": "node[type]",
        "style": {
          "label": "data(label)"
        }
      }, {
        "selector": "edge",
        "style": {
          "width": 1,
          "curve-style": "straight"
        }
      }, {
        "selector": "edge[arrow]",
        "style": {
          "target-arrow-shape": "data(arrow)",
          "width": "5px"
        }
      }, {
        "selector": "edge.hollow",
        "style": {
          "target-arrow-fill": "hollow"
        }
      }]

    // // the default values of each option are outlined below:
    let defaults = {
      menuRadius: function(ele: any){ return 100; }, // the outer radius (node center to the end of the menu) in pixels. It is added to the rendered size of the node. Can either be a number or function as in the example.
      selector: 'node', // elements matching this Cytoscape.js selector will trigger cxtmenus
      commands: [ // an array of commands to list in the menu or a function that returns the array
        
        { // example command
            fillColor: 'rgba(35, 131, 200, 0.75)', // optional: custom background color for item
            content: 'Open Information', // html/text content to be displayed in the menu
            contentStyle: {}, // css key:value pairs to set the command's css in js if you want
            select: function(ele: any){ // a function to execute when the command is selected
                let type= ele.data().type;
                let key= ele.data().key;
                window.open(`https://developer.sgmarkets.com/explore/${type}/${key.replace('-Non-Production', '').replace('-Production', '')}` );
            },
            enabled: true // whether the command is selectable
          },
          { // example command
            fillColor: 'rgba(247, 0, 71, 0.75)', // optional: custom background color for item
            content: 'View Credentials', // html/text content to be displayed in the menu
            contentStyle: {}, // css key:value pairs to set the command's css in js if you want
            select: function(ele: any){ // a function to execute when the command is selected
                let type= ele.data().type;
                let key= ele.data().key;
                window.open(`https://developer.sgmarkets.com/explore/${type}/${key.replace('-Non-Production', '').replace('-Production', '')}/${type == 'api' ? 'v1/': ''}credentials` );
            },
            enabled: true // whether the command is selectable
          },
          { // example command
            fillColor: 'rgba(48, 141, 53, 0.75)', // optional: custom background color for item
            content: 'Inspect', // html/text content to be displayed in the menu
            contentStyle: {}, // css key:value pairs to set the command's css in js if you want
            select: function(ele: any){ // a function to execute when the command is selected
              let key= ele.data().key.replace('-Non-Production', '').replace('-Production', '');
              let env= environment == null ? ele.data().key.replace( `${key}-`, '').toLowerCase() : environment;
              var host = window.location.protocol + "//" + window.location.host;
              window.open(`${host}/dx-api-chart/${key}/${env}` );
              
              console.log( ele.id() ) // `ele` holds the reference to the active element
            },
            enabled: true // whether the command is selectable
          }
      ], // function( ele ){ return [ /*...*/ ] }, // a function that returns commands or a promise of commands
      fillColor: 'rgba(0, 0, 0, 0.75)', // the background colour of the menu
      activeFillColor: 'rgba(1, 105, 217, 0.75)', // the colour used to indicate the selected command
      activePadding: 20, // additional size in pixels for the active command
      indicatorSize: 24, // the size in pixels of the pointer to the active command, will default to the node size if the node size is smaller than the indicator size, 
      separatorWidth: 3, // the empty spacing in pixels between successive commands
      spotlightPadding: 4, // extra spacing in pixels between the element and the spotlight
      adaptativeNodeSpotlightRadius: false, // specify whether the spotlight radius should adapt to the node size
      minSpotlightRadius: 24, // the minimum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
      maxSpotlightRadius: 38, // the maximum radius in pixels of the spotlight (ignored for the node if adaptativeNodeSpotlightRadius is enabled but still used for the edge & background)
      openMenuEvents: 'cxttapstart taphold', // space-separated cytoscape events that will open the menu; only `cxttapstart` and/or `taphold` work here
      itemColor: 'white', // the colour of text in the command's content
      itemTextShadowColor: 'transparent', // the text shadow colour of the command's content
      zIndex: 9999, // the z-index of the ui div
      atMouse: false, // draw menu at mouse position
      outsideMenuCancel: false // if set to a number, this will cancel the command if the pointer is released outside of the spotlight, padded by the number given 
    };

    function makePopper(ele: any) {
        let ref = ele.popperRef(); // used only for positioning
    
        let dummyDomEle = document.createElement("div");
        ele.tippy = tippy(dummyDomEle, { // tippy options:
          content: () => {
            let content = document.createElement('div');
    
            content.innerHTML = ele.id().replace('-Production', '').replace('-Non-Production', '');
    
            return content;
          },
          getReferenceClientRect: ref.getBoundingClientRect, // https://atomiks.github.io/tippyjs/v6/all-props/#getreferenceclientrect
          trigger: 'manual' // probably want manual mode
        });
  };

  const SaveLayout = (cy: any) => {
    console.log("save layout");
    saveLayout(JSON.stringify( cy.json() ));
  };
  const ViewDetails = (key: string) => {
    setSelectedKey(key);
  };
  
  var selectedCy: any;
  const scrollCallback = useCallback(() => {
    SaveLayout(selectedCy);
    // do sth.
}, []);
  return (
    <>
      <div className='dx-chart-button'>
        <Button color="success" outline size="sm" id="saveLayout" >Save Layout</Button>
      </div>
      <div>{selectedKey}</div>
      {/* <PanelDetails selectedKey={selectedKey} closePanel={() => setSelectedKey(null)} /> */}
      <CytoscapeComponent elements={nodes} cy={(cy: any) => { 
        cy.cxtmenu( defaults );
        cy.ready(function() {
            cy.elements().forEach(function(ele: any) {
              makePopper(ele);
            });
          });
        cy.elements().unbind('mouseover');
        cy.elements().bind('mouseover', (event: any) => event.target.tippy.show());
        cy.elements().bind('mouseover', (event: any) => console.log(event.target.data().level));
        cy.elements().unbind('click');
        cy.elements().bind('click', (event: any) => ViewDetails(event.target.data().id));

        var saveLayoutButton = document.getElementById('saveLayout');
        function _save(eventObj: any) { SaveLayout(cy); }

        selectedCy = cy;
        if (saveLayoutButton != undefined) {
          saveLayoutButton.removeEventListener('click', scrollCallback, true);
          saveLayoutButton.addEventListener('click', scrollCallback, true);
        }
    
        cy.elements().unbind('mouseout');
        cy.elements().bind('mouseout', (event: any) => event.target.tippy.hide());

     }}  layout={layout} stylesheet={stylesheet} style={ { width: '100%', height: '90%', marginTop:'40px' } } />
     </>
  );
};

export default DxChart;
