import { type ReactElement, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { Header } from './Header.tsx';
import { Footer } from './Footer.tsx';

export const FullLayout = (): ReactElement => {
  return (
    <div className="app">
        <Header />

        <main className="p-4 container-fluid">
        <Outlet />
        </main>

        <Footer />
        {/* <SgwtWebAnalytics siteId={matomo.siteId} baseUrl={matomo.url} debug={true} /> */}
      </div>
  );
};