import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type HealthGraphDto, type ServiceGraphDto, type ApplicationLink } from '../models/healthGraph.ts'
import { getConfig } from '@/config/config.ts';


export class LinksService {
  save(service: ServiceGraphDto, links?: ApplicationLink[]): Promise<boolean> {
    const { apiUrl } = getConfig();
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ links: links }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    return fetch(
      `${apiUrl}/api/v2/links/${service.key}`,
      requestOptions as any
    ).then((t) => {
      return true;
    });  
  }
}