import { type ReactElement, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { Header } from './Header.tsx';
import { Footer } from './Footer.tsx';
import { GlobalHealthService } from '@/services/GlobalHealthService.ts'
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { type GlobalHealthSgConnectDto, type GlobalHealthDto } from '@/models/globalHealth.ts'

export const Layout = (): ReactElement => {
  
  const [isSgConnectError, setIsSgConnectError] = useState<boolean>(false);
  const [globalHealth, setGlobalHealth] = useState<GlobalHealthDto | null>(null);
  
  const  IsSgConnectHealthy= () => {
    return new GlobalHealthService().getGlobalHeathSgConnect()
    .then((result: GlobalHealthSgConnectDto) => {
        if (result.sgConnectStatus === "Healthy" ) {
          setIsSgConnectError(false);
          return;
        }
        setIsSgConnectError(true);
    })
    .catch((reason) => {
      setIsSgConnectError(true);
    });
  }
  const  IsSharedInfraHealthy= () => {
    return new GlobalHealthService().getGlobalHeath()
    .then((result: GlobalHealthDto) => {
        setGlobalHealth(result);
    })
    .catch((reason) => {
      setGlobalHealth(null);
    });
  }
  

  useEffect(() => {
    IsSgConnectHealthy();
    IsSharedInfraHealthy();
    new ApplicationsService().get().then((healthGraph: any) => {
  })
  .catch((reason: any) => {
      window.location.href = "https://shared.sgmarkets.com/unauthorized/?redirectUri=https://status.sgmarkets.com";
  })
  }, []);
  
  function prettifyDateTime(str) {
    let date = new Date(str);
    // There's other ways of formatting this return
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }

  return (
    <div className="app">
        <Header />

        <main className="p-4 container">
        {isSgConnectError === true ? 
        <div className="card mb-3  alert alert-outline-warning">
            <div className="card-body">
              <h3 className="card-title line-height-1">Global incident</h3>
              <p className="card-text">
                Global Incident on Sg|Connect.
              </p>
            </div>
          </div> : null
        }
        {globalHealth != null && (globalHealth.azureStatus != null || globalHealth.azureStatus != null) ?
        
          <div className="card mb-3  alert alert-outline-warning">
            <div className="card-body">
              <h3 className="card-title line-height-1">Global incident</h3>
              <p className="card-text">
                {globalHealth.azureStatus != null ? 
                <>
                  <h6 className='mb-3 mt-3'>{globalHealth.azureStatus.title}: </h6>
                  <span className='ps-3'>{prettifyDateTime(globalHealth.azureStatus.publicationDate) }: <span  className='ps-3'  style={{ display: "block" }} dangerouslySetInnerHTML={{__html: globalHealth.azureStatus.description!}}></span></span>
                </> : null}
                {globalHealth.sgApimStatus != null ? 
                <>
                  <h6>Incident on SG|APIM</h6>
                  <span>{globalHealth.sgApimStatus.publicationDate}: {globalHealth.sgApimStatus.description}</span>
                </> : null}
              </p>
            </div>
          </div> : null
        }
        <Outlet />
        </main>

        <Footer />
        {/* <SgwtWebAnalytics siteId={matomo.siteId} baseUrl={matomo.url} debug={true} /> */}
      </div>
  );
};