import { type ReactElement, useEffect, useState } from 'react';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import Loading from '../common/Loading.tsx';
import { Link } from 'react-router-dom';
import './servicesSubcriptionsDependencies.css';

export function ServicesSubcriptionsDependencies(): ReactElement {

    const [applications, setApplications] = useState<ApplicationsDto | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const RefreshData= () => {
        setIsLoading(true);
        new ApplicationsService().getUnauthentified().then((applications: ApplicationsDto) => {
            setIsLoading(false);
            setApplications(applications);
        });
    };
    
    useEffect(() => {
        RefreshData();
    }, []);
  
    return (
        <div>
            <div className="row pt-5  d-flex">
                <h4>SGM Services: Subscription dependencies</h4>
                <div className="with-line large"></div>
            </div>
            <div className="row pt-5  d-flex">
                <Loading isLoading={isLoading} />
                
                <div className="col-12d-flex">
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Department</th>
                                <th>Lifecycle</th>
                                <th>Non-Production</th>
                                <th>Production</th>
                            </tr>
                        </thead>
                        <tbody>
                        {isLoading == false && applications?.webApps
                            .map((service: ApplicationDto) => (
                                    <tr>
                                        <td className=".text-icon"> <div className={`card d-flex justify-content-center align-items-center text-black bg-white border p-0 text-icon bookmarkable-card-frame-small text-icon-${service.status}`}>
                                                {service.shortName}
                                            </div>
                                        </td>
                                        <td>{service.name}</td>
                                        <td>{service.managerDepartment}</td>
                                        <td>{service.lifeCycle}</td>
                                        <td><Link to={`/dx-chart/${service.key}/non-production`} className="fw-semibold text-truncate mb-0">View</Link></td>
                                        <td><Link to={`/dx-chart/${service.key}/production`} className="fw-semibold text-truncate mb-0">View</Link></td>
                                    </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}