import { type FormEvent, type ReactElement, useEffect, useState } from 'react';
import { type FC } from 'react';
import type { ServiceGraphDto, ApplicationLink } from '@/models/healthGraph.ts';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Row, Col } from 'reactstrap';


export type EditLinksModalProps = {
    service: ServiceGraphDto | undefined;
    closeModal: () => void;
    save: (newEntries: ApplicationLink[]) => void;
  };

  
const EditLinksModal: FC<EditLinksModalProps>= ({
    service,
    closeModal,
    save
}: EditLinksModalProps) => {

  
  const [linkEntries, setLinkEntries] = useState<ApplicationLink[]>([]);
  const [newLinkEntries, setNewLinkEntries] = useState<ApplicationLink[]>([]);

  const SaveSettings = () => {
    const links = linkEntries.concat(newLinkEntries);
    save(links);
  };
  
  const AddLink = () => {
    setNewLinkEntries([...newLinkEntries, {
        id: newLinkEntries.length,
        customIconUrl: '',
        name: '',
        type: '',
        url: ''
    }]);        
  };


  const ChangeLinkName= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.name = event.currentTarget.value;
    setNewLinkEntries([...newLinkEntries]);
  }
  const ChangeLinkUrl= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.url = event.currentTarget.value;
    setNewLinkEntries([...newLinkEntries]);
  }
  const ChangeLinkCustomIconUrl= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.customIconUrl = event.currentTarget.value;
    setNewLinkEntries([...newLinkEntries]);
  }

  const ChangeLinkType= (event: FormEvent<HTMLSelectElement>, link: ApplicationLink): void  => {
    link.type = event.currentTarget.value;
    setNewLinkEntries([...newLinkEntries]);
  }

  const ChangeExistingLinkName= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.name = event.currentTarget.value;
    setLinkEntries([...linkEntries]);
  }
  const ChangeExistingLinkUrl= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.url = event.currentTarget.value;
    setLinkEntries([...linkEntries]);
  }
  const ChangeExistingLinkCustomIconUrl= (event: FormEvent<HTMLInputElement>, link: ApplicationLink): void  => {
    link.customIconUrl = event.currentTarget.value;
    setLinkEntries([...linkEntries]);
  }

  const ChangeExistingLinkType= (event: FormEvent<HTMLSelectElement>, link: ApplicationLink): void  => {
    link.type = event.currentTarget.value;
    setLinkEntries([...linkEntries]);
  }

  useEffect(() => {
    if(service?.applicationLinks != undefined)
      setLinkEntries(service?.applicationLinks!);
    else
      setLinkEntries([]);
  }, []);


  return (
    <div className="row pt-2  d-flex">
        <ModalHeader>Edit Links for: {service?.name}</ModalHeader>
        <ModalBody>
        <Form className='pt-3'>
            <Label className='form-card-title'>Custom Links:</Label>
            <FormGroup className='form-group-card'>
            { linkEntries?.map((link => (
              <Row className='mb-2'>
                  <Col sm="3" className='text-v-align'>
                    <Input type="text" placeholder="Enter Name" onInput={(evt) => ChangeExistingLinkName(evt, link)} defaultValue={link.name}></Input>
                  </Col>
                    <Col sm="3" className='text-v-align'>
                      <select className="form-select w-100" onChange={(evt) => ChangeExistingLinkType(evt, link)} value={link.type}>
                          <option value="">Select type</option>
                          <option value="argocd">ArgoCD</option>
                          <option value="azure">Azure</option>
                          <option value="custom">Custom</option>
                          <option value="do-it-now">Do It Now</option>
                          <option value="github-actions">Github Actions</option>
                          <option value="kibana">Kibana</option>
                          <option value="sonar">Sonar</option>
                      </select>
                    </Col>
                    <Col sm="3" className='text-v-align'>
                      <Input type="text" placeholder="Enter url" onInput={(evt) => ChangeExistingLinkUrl(evt, link)} defaultValue={link.url}></Input>
                    </Col>
                    <Col sm="3" className='text-v-align'>
                      <Input type="text" placeholder="Enter custom icon Url" readOnly={link.type !== 'custom'} onInput={(evt) => ChangeExistingLinkCustomIconUrl(evt, link)} defaultValue={link.customIconUrl}></Input>
                    </Col>
              </Row>
            )))
            }
            { newLinkEntries.map((link => (
                <Row className='mb-2'>
                  <Col sm="3" className='text-v-align'>
                    <Input type="text" placeholder="Enter Name" onInput={(evt) => ChangeLinkName(evt, link)} defaultValue={link.name}></Input>
                  </Col>
                    <Col sm="3" className='text-v-align'>
                      <select className="form-select w-100" onChange={(evt) => ChangeLinkType(evt, link)}>
                          <option value="">Select type</option>
                          <option value="argocd">ArgoCD</option>
                          <option value="azure">Azure</option>
                          <option value="custom">Custom</option>
                          <option value="do-it-now">Do It Now</option>
                          <option value="github-actions">Github Actions</option>
                          <option value="kibana">Kibana</option>
                          <option value="sonar">Sonar</option>
                      </select>
                    </Col>
                    <Col sm="3" className='text-v-align'>
                      <Input type="text" placeholder="Enter url" onInput={(evt) => ChangeLinkUrl(evt, link)} defaultValue={link.url}></Input>
                    </Col>
                    <Col sm="3" className='text-v-align'>
                      <Input type="text" placeholder="Enter custom icon Url" readOnly={link.type !== 'custom'} onInput={(evt) => ChangeLinkCustomIconUrl(evt, link)} defaultValue={link.customIconUrl}></Input>
                    </Col>
                </Row>
              )))}
              <Row>
                <Col sm="12" className='text-end'>
                    <Button color="primary" onClick={AddLink}><i className="icon icon-md me-2">add</i>Add Link</Button>
                </Col>
              </Row>
            </FormGroup>
        </Form>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={SaveSettings}>
            Save
            </Button>
            <Button color="secondary" onClick={closeModal}>
            Cancel
            </Button>
        </ModalFooter>

    </div>
  );
};

export default EditLinksModal;