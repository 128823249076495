
import React, { type FC } from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  pageName: string;
  parentName: string;
  parentLink: string;
}

const Breadcrumb: FC<BreadcrumbProps> = ({
  pageName = '...',
  parentName = '...',
  parentLink = '...',
}: BreadcrumbProps) =>
    <nav aria-label="breadcrumb">
    <ol className="breadcrumb mb-4">
      <li className="breadcrumb-item">
        <Link to={parentLink}>{parentName}</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        {pageName}
      </li>
    </ol>
    </nav>;

export default Breadcrumb;

