export const PATHS = {
    root: '/',
    home: '/',
    services: 'services',
    dxChart: 'dx-chart/:key/:environment',
    dxApiChart: 'dx-api-chart/:key/:environment',
    healthsTablePage: 'table/:key',
    healths: 'healths/:key',
    healthsEnv: 'healths/:key/:env',
    health: 'health',
  } as const;