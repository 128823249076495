import { type ReactElement } from 'react';
import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';
import { ConnectedHome} from '../components/home/connectedHome.tsx'
import { Home} from '../components/home/home.tsx'

export function HomePage(): ReactElement {

  if (sgwtConnect.isAuthorized())
    return <ConnectedHome/>;
  return <Home />
}