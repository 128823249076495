import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type LayoutDto } from '../models/layout.ts'
import { getConfig } from '@/config/config.ts';


export class LayoutService {
  getLayout(key?: string): Promise<LayoutDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/layouts/${key}`, requestOptions)
    .then((_response: Response) => {
        return _response.json();
    });
  }
  saveLayout(key: string | undefined, layout: string): Promise<boolean> {
    const { apiUrl } = getConfig();
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({ key: key, layout: layout }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    return fetch(
      `${apiUrl}/api/v2/layouts`,
      requestOptions as any
    ).then((t) => {
      return true;
    });  
  }
}