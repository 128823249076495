import { SgwtMiniFooter } from '@sgwt/sgwt-widgets-react';
import { getConfig } from '@/config/config.ts';

export function MiniFooter(): JSX.Element {
  const { environment } = getConfig();
  return (
    <SgwtMiniFooter
      type="micro"
      design="light"
      mode="sg-markets"
      cookiesConsent={environment === 'dev'}
      contactUsByHelpCenter="sgwt-help-center"
    />
  );
}
