import { type FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './applicationCard.css';
import {type EnvironmentClass, type ApplicationDto} from '@/models/services.ts'

type ApplicationCardProps = {
    service: any;
    readonly?: boolean;
    refreshData: () => void;
    toggleBookmarks: (e: any, application: ApplicationDto, currentStatus: boolean) => void;
    editSettings: (e: any, application: ApplicationDto) => void;
    showWarning: () => void;
};

const getStatusIcon = (status: string) =>  {
  return `card card-raising card-bordered m-2 mb-2 mt-2 w-100 card-${status === 'Healthy' ? 'healthy' : status === 'Unhealthy' ? 'unhealthy' :'degraded' }`;
}

  const DisplayEnvironments = (environments: EnvironmentClass[]) => {
    const OpenWindow = (e: any, url: string) => {
      window.open(url, '_blank').focus();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }

    return environments?.map(({ name, status, statusPingdom, urls }) =>
      statusPingdom != null ? (
        <span onClick={(e) => OpenWindow(e, urls[0])}>
          <span className={`badge me-2 mb-2 badge-outline-${status === 'Healthy' ? 'success' : status === 'Unhealthy' ? 'danger' : 'warning' }`} >
            {' '}
            <span>{name}</span>{' '}
            <span className={ `icon-pingdom-${statusPingdom.status == 'Healthy' ? 'up' : statusPingdom.status == 'Unealthy' ? 'down': 'other'}` }></span>
          </span>
        </span>
      ) : (
        <span onClick={(e) => OpenWindow(e, urls[0])}>
          <span className={ `badge me-2 mb-2 badge-outline-${status === 'Healthy' ? 'success' : status === 'Unhealthy' ? 'danger' : 'warning'}` }>
            {' '}
            {name}{' '}
          </span>
        </span>
      )
    );
  }

  const formatCasing = (str: string) => {
    if (str == null)
      return str;
    return str
      .replace(/(\B)[^ ]*/g, match => (match.toLowerCase()))
      .replace(/^[^ ]/g, match => (match.toUpperCase()))
      .toUpperCase();
  }

  const checkAccessibility = (e: any, isConfigured: boolean, func: any) => {
    if (!isConfigured) {
      func();
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
    return true;
  }

const ApplicationCard: FC<ApplicationCardProps> = ({ service, refreshData, readonly, toggleBookmarks, editSettings, showWarning }: ApplicationCardProps) => (
    <div className={getStatusIcon(service.status)}>
    <Link className='no-text-link' onClick={(e) => checkAccessibility(e, service.isConfigured, showWarning) }
    to={readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? '/' : `/healths/${service.key}`}
  >
    <div className="card-header pe-3 ps-3 pb-0 pt-3">
      <div className="d-flex align-items-center border-bottom border-secondary border-opacity-10 pb-2">
        <div className="d-flex flex-grow-1 align-items-center overflow-hidden">
          <div>
            <div className="card-body d-flex align-items-center justify-content-center p-0">
              <div className="card d-flex justify-content-center align-items-center text-black bg-white border p-0 text-icon bookmarkable-card-frame-small">
                {service.shortName}
              </div>
            </div>
          </div>
          <div className="flex-shrink-1 px-3 overflow-hidden">
            <Link
              to={readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? '/' : `/healths/${service.key}`}
              className="fw-semibold text-truncate mb-0"
            >
              {service.name}
            </Link>
            {readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? null : <i className={`icon ms-2 icon-app icon-setting ${service.isConfigured ? "text-success" : "text-danger"}`} onClick={(e) => editSettings(e, service)} title="Settings">settings</i>}
          </div>
        </div>
        <div className="flex-shrink-1">
        {readonly || (service.status != 'Healthy' && service.status != 'Unhealthy') ? null : <i className="icon ms-2 icon-app icon-star" onClick={(e) => toggleBookmarks(e, service, service.isBookmarked)} title="Add to bookmark">{service.isBookmarked ? 'star' : 'star_outline'}</i> }
        </div>
      </div>
    </div>
    <div className="card-body pr-3 pl-3 pb-1 pt-2 ">
    Department: {service.managerDepartment}
    </div>
    <div className="card-body pr-3 pl-3 pb-3 pt-1 ">
    Hosting: {formatCasing(service.hosting)}
    </div>
    
    <div className="card-body pr-3 pl-3 pb-3 pt-2 ">
      <div className="mb-2">
        {DisplayEnvironments(service.environments)}
      </div>
      {service.status === 'Unhealthy' && (
        <>
          <div>
            <span className="text-secondary">
              Partial incident: 
            </span>
          </div>
        </>
      )}
    </div>
    </Link>
    </div>
);

export default ApplicationCard;

