import { useIntl } from 'react-intl';
import { SgwtAccountCenter } from '@sgwt/sgwt-widgets-react';
import { updateTheme } from '@/utils/theme.ts';
import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';

export function AccountCenter(): JSX.Element {
  const intl = useIntl();

function login() {
  sgwtConnect.requestAuthorization()
} 

  return (
    <SgwtAccountCenter
      availableLanguages={['fr', 'en']}
      authentication="sg-connect"
      mode="sg-markets"
      language={intl.locale}
      producerCode={import.meta.env.VITE_PRODUCER_CODE}
      onSignInRequest={login}
      showSignInButton={!sgwtConnect.isAuthorized()}
      onThemeChanged={e => {
        const theme = e.themeChosen;
        updateTheme(theme);
      }}
    />
  );
}
