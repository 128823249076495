import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';

import { App } from './components/App.tsx';
import { SGWTConnectCore, SGWTConnectError } from '@sgwt/connect-core';
import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';
import { type SgwtConnectHTMLElement, SgwtWidgetContextProvider } from '@sgwt/sgwt-widgets-react';
import { GlobalHealthService } from '@/services/GlobalHealthService.ts'
import { type GlobalHealthSgConnectDto } from '@/models/globalHealth.ts'

setupSgwtConnectWidget(sgwtConnect);

const  IsSgConnectHealthy= () => {
  return new GlobalHealthService().getGlobalHeathSgConnect()
  .then((result: GlobalHealthSgConnectDto) => {
      if (result.sgConnectStatus === "Healthy" ) {
        return true;
      }
      return false;
  })
  .catch((reason) => {
    return false;
  });
}



if (sgwtConnect.isAuthorized()) {
  renderApp();
} else 
{
  IsSgConnectHealthy().then((result: boolean) => {
    if (!result) {
      renderApp();
    }
    else {
      const authorizationError = sgwtConnect.getAuthorizationError();
      if (authorizationError !== null) {
        renderApp();
      } else {
        sgwtConnect.requestAuthorization();
      }
    }
  });
}

function renderApp() {
  const rootElement = document.getElementById('root')!;
  const root = createRoot(rootElement);

  root.render(
    <StrictMode>
      <SgwtWidgetContextProvider infrastructure="azure">
        <App />
      </SgwtWidgetContextProvider>
    </StrictMode>,
  );
}

function renderError(authorizationError: SGWTConnectError) {
  document.body.innerHTML = `
      <div class='alert alert-danger' role='alert'>
        Authorization error: ${authorizationError}.
      </div>`;
}

function setupSgwtConnectWidget(sgwtConnect: SGWTConnectCore) {
  const widget = document.querySelector<SgwtConnectHTMLElement>('sgwt-connect');
  if (widget) {
    // When the code is executed, the swgtConnectWidget may not have been initialized. So, we need to check that, otherwise calling
    // `swgtConnectWidget.setSgwtConnectInstance()` will throw an error.
    if (typeof widget.setSgwtConnectInstance === 'undefined') {
      // Widget is not initialized yet, so we will wait the event that indicates the swgtConnectWidget is ready...
      const handleSgwtConnectReady = () => {
        widget.removeEventListener('sgwt-connect--ready', handleSgwtConnectReady);
        widget.setSgwtConnectInstance(sgwtConnect);
      };

      widget.addEventListener('sgwt-connect--ready', handleSgwtConnectReady);
    } else {
      // Widget is initialized...
      widget.setSgwtConnectInstance(sgwtConnect);
    }
  }
}