import { type FormEvent, type ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { HealthService } from '@/services/HealthService.ts'

import { sgwtConnect } from '@/sgwt/sgwtConnect.ts';
import { type HealthDto, type Check, type Problem } from '@/models/health.ts'
import Breadcrumb from '../../components/common/Breadcrumb.tsx'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Nav, NavLink, NavItem,TabContent,TabPane, Row, Col, Card, CardTitle, CardText, CardBody  } from 'reactstrap';
import { ERR_TEMPORARILY_UNAVAILABLE } from '@sgwt/connect-core';

export function HealthVisualize(): ReactElement {

    let { key } = useParams();
    let { env } = useParams();
    
    const [health, setHealth] = useState<HealthDto|null>(null);
    const [error, setError] = useState<Error|null>(null);
    const [currentEnvironment, setCurrentEnvironment] = useState<string|null>('https://sgm-order-pizza-1-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen');
    const [searchTerm, setSearchTerm] = useState<string|null>(null);
    const delay = 350

    const ChangeSearch= (): void  => {
        if (searchTerm != null && searchTerm?.length >= 3) {
            new HealthService().get(encodeURIComponent(searchTerm)).then((health: HealthDto) => {
                setHealth(health);
                setError(null)
            })
            .catch((error: Error) => {
                setHealth(null);
                setError(error)
            });
        }
    }
    
    useEffect(() => {
        const delayFn = setTimeout(() => ChangeSearch(), delay);
        return () => clearTimeout(delayFn);
    }, [searchTerm, delay]);
   
    // useEffect(() => {
    // }, []);
  
    return (

        <div>
            { sgwtConnect.isAuthorized() ? 
            <>
                <Form className='pt-3'>
                    <FormGroup className='form-group-card'>
                    <Label for="producerCode">Url or api code:</Label>
                        <Input
                            id="apiCode"
                            name="apiCode"
                            placeholder="https://sgm-order-pizza-1-dev.sgr-3819-dev-insight-kube12-dev.wkube.eu-fr-paris.cloud.socgen"
                            type="text"
                            onInput={(evt) => setSearchTerm(evt.currentTarget.value)}
                            value={searchTerm!}
                        />
                    </FormGroup>
                </Form>
                <div>
                    {health !== null && error == null ?
                    <>
                        <div>Api Code: {health?.apiCode}</div>
                        <div>Status: <span className={`fw-bold ${health?.status == 'degraded' ? 'text-warning' : health?.status == 'unhealthy' ? 'text-danger' : ''}`}>{health?.status}</span></div>
                        <div>Execution DateTime: {health?.apiCode}</div>
                        {health?.checks?.map((check: Check) => {
                            return <div className={`form-group-card mb-2 mt-2 ms-4 ${check.status == 'degraded' ? 'border-warning text-warning' : check.status == 'unhealthy' ? 'border-danger text-danger' : 'border-success text-success'}`}>
                                <div className='ms-2'>Status: <span className='fw-bold'>{check.status}</span></div>
                                <div className='ms-2 '>Name: {check.name}</div>
                                <div className='ms-2'>Type: {check.type}</div>
                                <div className='ms-2'>Description: {check.description}</div>
                                {check?.problems?.map((problem: Problem) => {
                                    return <div className='ms-2 text-danger'>Problem: {problem.detail}</div>
                                    }
                                )}
                            </div>
                        })}
                    </>
                    : null }
                    {error != null ? 
                    <>
                    <div>An error occurs: {error.message}</div>
                    </>
                    :  null}
                </div>
            </> : <></>
            }
        </div>
    );
}