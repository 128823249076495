import { type ReactElement, useEffect, useState } from 'react';
import { ApplicationsService } from '@/services/ApplicationsService.ts'
import { type ApplicationsDto, type ApplicationDto } from '@/models/services.ts'
import Bookmarks from './bookmarks.tsx'
import ApplicationCard from './applicationCard.tsx'
import SettingsForm from './settingsForm.tsx'
import Loading from '../common/Loading.tsx';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export function Home(): ReactElement {

    const [applications, setApplications] = useState<ApplicationsDto | null>(null);
    const [bookmarks, setBookmarks] = useState<ApplicationDto[] | null>(null);
    const [filter, setFilter] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const RefreshData= () => {
        setIsLoading(true);
        new ApplicationsService().getUnauthentified().then((applications: ApplicationsDto) => {
            const bookmarks = applications?.webApps
                ?.filter(
                  (f) =>
                    f.isBookmarked
                )
            setIsLoading(false);
            setBookmarks(bookmarks);
            setApplications(applications);
        });
    };
    

    const ToggleBookmarks = (application: ApplicationDto, currentStatus: boolean) => {
    }

    const EditSettings = (application: ApplicationDto) => {
    };
  
    useEffect(() => {
        RefreshData();
    }, []);
  
    return (
        <div>
            <div className="row pt-5  d-flex">
                <div
                    style={{ position: 'relative', height: '35px' }}
                    className="w-100"
                >
                    <h4>Bookmarks</h4>
                    <div className="with-line"></div>
                </div>
                <Loading isLoading={isLoading} />
                {isLoading == false ? <span>Not Available when unauthentified</span> : <></>}
                
            </div>

            <div className="row pt-5  d-flex">
                <h4>SGM Services</h4>
                <div className="with-line"></div>
                <div className="input-group">
                <div className="col-md input-group  p-2">
                    <input
                    type="text"
                    className="form-control"
                    id="filter"
                    name="filter"
                    value={filter}
                    onChange={(event) => setFilter(event.target.value)}
                    ></input>
                </div>
                <div className="col-md input-group  p-2">
                    <select className="form-select w-100">
                        <option value="">All</option>
                        <option value="Healthy">Healthy</option>
                        <option value="Unhealthy">Unhealthy</option>
                        <option value="Degraded">Degraded</option>
                    </select>
                </div>
                </div>
            </div>
            <div className="row pt-5  d-flex">
                <Loading isLoading={isLoading} />
                {isLoading == false && applications?.webApps
                ?.filter(
                    (f) =>
                    f.name.toLowerCase().includes(filter.toLowerCase()) ||
                    f.managerDepartment.toLowerCase().includes(filter.toLowerCase()) ||
                    filter === ''
                )
                .map((service) => (
                    <div className="col-12 col-lg-6 col-xl-4 d-flex">
                    <ApplicationCard service={service} refreshData={RefreshData} toggleBookmarks={ToggleBookmarks} editSettings={EditSettings} readonly></ApplicationCard>
                    </div>
                ))}
            </div>
        </div>
    );
}