import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type HealthDto } from '@/models/health.ts'
import { getConfig } from '@/config/config.ts';


export class HealthService {
  get(search: string): Promise<HealthDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    const encoded = encodeURIComponent(search);

    return fetch(`${apiUrl}/api/v2/healths/${encoded}`, requestOptions)
    .then((_response: Response) => {
        if( _response.ok)
        {
          return _response.json();
        }
        else {
          if (_response.status == 404)
            throw new Error('Url not found:'+encoded);
          else if (_response.status == 200)
            return {status: "Healthy"}
          else
            throw new Error('Healthcheck is not in a recognized json format:'+encoded);
        }
    })
    .catch((reason: any) => {
      throw reason;
    });
  }
}