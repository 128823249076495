import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type GlobalHealthDto, type GlobalHealthSgConnectDto } from '../models/globalHealth.ts'
import { getConfig } from '@/config/config.ts';


export class GlobalHealthService {
  getGlobalHeath(): Promise<GlobalHealthDto> {

      const { apiUrl } = getConfig();
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
          },
        } as RequestInit;
  
      return fetch(`${apiUrl}/api/v2/global-healths`, requestOptions)
      .then((_response: Response) => {
          return _response.json();
      });
  }
  getGlobalHeathSgConnect(): Promise<GlobalHealthSgConnectDto> {

      const { apiUrl } = getConfig();
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
          },
        } as RequestInit;
  
      return fetch(`${apiUrl}/api/v2/global-healths/sgconnect`, requestOptions)
      .then((_response: Response) => {
          return _response.json();
      });
  }
}