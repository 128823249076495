import { getAuthorizationHeader } from '../sgwt/sgwtConnect.ts'
import { type ApplicationsDto, type ApplicationDto } from '../models/services.ts'
import { type ServicesDto, type ApisDto } from '../models/graph.ts'
import { type APIConfigurationDto, type APIEntryDto } from '../models/apiConfiguration.ts'
import { type GlobalHealthDto } from '../models/globalHealth.ts'
import { getConfig } from '@/config/config.ts';


export class ApplicationsService {
  get(): Promise<ApplicationsDto> {

      const { apiUrl } = getConfig();
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
          },
        } as RequestInit;
  
      return fetch(`${apiUrl}/api/v2/webapps/bookmarks`, requestOptions)
      .then((_response: Response) => {
          return _response.json();
      });
  }

  getUnauthentified(): Promise<ApplicationsDto> {

      const { apiUrl } = getConfig();
      const requestOptions = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: getAuthorizationHeader(),
          },
        } as RequestInit;
  
      return fetch(`${apiUrl}/api/v2/webapps`, requestOptions)
      .then((_response: Response) => {
          return _response.json();
      });
  }
  
  getGraph(key: string, isProduction: boolean): Promise<ServicesDto> {

    const { apiUrl } = getConfig();
    const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getAuthorizationHeader(),
        },
      } as RequestInit;

    return fetch(`${apiUrl}/api/v2/webapps/${key}/graphs/by-subscriptions/${isProduction ? "production" : "non-production"}`, requestOptions)
    .then((_response: Response) => {
        return _response.json();
    });
}
  
getApiGraph(key?: string, environment?: string): Promise<ApisDto> {

  const { apiUrl } = getConfig();
  const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    } as RequestInit;

  return fetch(`${apiUrl}/api/v2/apis/${key}/graphs/${environment}`, requestOptions)
  .then((_response: Response) => {
      return _response.json();
  });
}
  
  toggleBookmarks(apiCode: string, currentStatus: boolean): Promise<boolean> {

    const { apiUrl } = getConfig();
    const requestOptions = {
      method: !currentStatus ? 'POST' : 'DELETE',
      body: JSON.stringify({ webAppKey: apiCode }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: getAuthorizationHeader(),
      },
    };
    return fetch(
      `${apiUrl}/api/v2/bookmarks`,
      requestOptions as any
    ).then((t) => {
      return true;
    });  
  }
}